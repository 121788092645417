<template>
  <v-card class="fill-height px-2 py-2">
    <v-alert
      border="bottom"
      class="mb-6"
      colored-border
      type="success"
      elevation="2">
      Langkah sewa
      <ol>
        <li>Pilih item yang mau disewa</li>
        <li>Klik icon WA untuk menghubungi admin</li>
        <li>Bayar via transfer atau COD</li>
        <li>Kirim bukti bayar dan foto KTP sebagai persyaratan sewa</li>
      </ol>
    </v-alert>

    <v-row class="pa-4">
      <v-col
        v-for="item in items"
        :key="item.title"
        class="pa-1"
        :cols="colsPerRow">
        <RentalListItemToo
          :item="item"
          @click="viewDetail(item)" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { RentalListItemToo } from '../components';

export default {
  components: {
    RentalListItemToo,
  },

  data() {
    const details = [{
      icon: 'fas fa-house',
      label: 'Pemakaian',
      value: ['indoor & outdoor'],
    }, {
      icon: 'fas fa-list',
      label: 'Fitur',
      value: ['cabin size, ringan, bisa 3 posisi'],
    }, {
      icon: 'fas fa-dollar-sign',
      label: 'Harga sewa',
      value: ['Rp 50.000,00 / pekan', 'Rp 100.000,00 / bulan'],
    }];

    return {
      items: [
        {
          title: 'Iconic',
          category: 'Stroller',
          src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg',
          flex: 12,
          isRentOut: true,
          price: 'Rp 80.000,00/pekan',
          details,
          status: 'rent_out',
        },
        {
          title: 'Nuna Leaf',
          category: 'Baby swing',
          src: 'https://cdn.vuetifyjs.com/images/cards/road.jpg',
          flex: 6,
          price: 'Rp 140.000,00/bulan',
          details,
          status: 'available',
        },
        {
          title: 'Family',
          category: 'Baby walker',
          src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg',
          flex: 6,
          isRentOut: true,
          price: 'Rp 50.000,00/bulan',
          details,
          status: 'available',
        },
        {
          title: 'Lion Fisher Price',
          category: 'Baby push walker',
          src: 'https://cdn.vuetifyjs.com/images/cards/road.jpg',
          flex: 6,
          price: 'Rp 50.000,00/bulan',
          details,
          status: 'booked',
        },
        {
          title: 'Mastella',
          category: 'Booster seat',
          src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg',
          flex: 6,
          price: 'Rp 50.000,00/bulan',
          details,
          status: 'booked',
        },
        {
          title: 'Parklon',
          category: 'Perosotan',
          src: 'https://cdn.vuetifyjs.com/images/cards/road.jpg',
          flex: 6,
          price: 'Rp 100.000,00/bulan',
          details,
          status: '',
        },
        {
          title: 'Labielle',
          category: 'Push bike',
          src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg',
          flex: 6,
          price: 'Rp 70.000,00/bulan',
          details,
          status: 'ready_soon',
        },
        {
          title: 'Exotic',
          category: 'Trike',
          src: 'https://cdn.vuetifyjs.com/images/cards/road.jpg',
          flex: 6,
          price: 'Rp 50.000,00/pekan',
          details,
          status: 'booked',
        },
        {
          title: 'Ergo baby',
          category: 'Baby carrier',
          src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg',
          flex: 6,
          isRentOut: true,
          price: 'Rp 60.000,00/bulan',
          details,
          status: 'ready_soon',
        },
        {
          title: 'Sugarbaby',
          category: 'Playmat',
          src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg',
          flex: 6,
          price: 'Rp 40.000,00/bulan',
          details,
          status: 'ready_soon',
        },
      ],
    };
  },

  computed: {
    colsPerRow() {
      if (this.$vuetify.breakpoint.xsOnly) return 12;
      if (this.$vuetify.breakpoint.smOnly) return 6;
      if (this.$vuetify.breakpoint.mdOnly) {
        return this.$vuetify.breakpoint.width > 1200 ? 4 : 6;
      }
      return this.$vuetify.breakpoint.width > 1500 ? 3 : 4;
    },
  },

  methods: {
    viewDetail(item) {
      console.log(item.title);
    },
  },
};
</script>
