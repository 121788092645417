<template>
  <v-card>
    <v-img
      :src="item.src"
      class="white--text align-end"
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
      height="200px" />

    <v-card-title>{{ item.title }}</v-card-title>

    <v-card-subtitle class="pb-0">{{ item.category }}</v-card-subtitle>

    <v-card-text class="pb-0 text-subtitle-1">
      {{ item.price }}
    </v-card-text>

    <v-card-actions>
      <v-btn
        color="orange lighten-2"
        text
        @click="showMore = !showMore">
        Lihat detail
      </v-btn>

      <v-btn
        icon
        @click="showMore = !showMore">
        <v-icon>{{ showMore ? 'fas fa-angle-up' : 'fas fa-angle-down' }}</v-icon>
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn
        bottom
        color="green"
        dark
        :disabled="item.isRentOut"
        fab
        :href="`https://wa.me/6282134336282?text=Bismillah, saya mau sewa ${item.title}, apakah available?`"
target="_blank"
        left
        small>
        <v-icon>mdi-whatsapp</v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="showMore">
        <v-divider></v-divider>

        <v-card-text>
          <v-list>
            <v-list-item
              v-for="(note, index) in item.details"
              :key="index"
              class="my-2"
              dense
              style="background: darkseagreen; border-radius: 10px">
              <v-list-item-content>
                <v-list-item-subtitle>
                  <ul>
                    <li
                      v-for="(val, valIndex) in note.value"
                      :key="`val-${valIndex}`">{{ val }}
                    </li>
                  </ul>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showMore: false,
    };
  },
};
</script>
