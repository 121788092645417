<template>
  <div class="text-center">
    <v-chip
      class="ma-2"
      :color="statusConfig.color"
      :text-color="statusConfig.color ? 'white' : ''">
      {{ statusConfig.label }}
    </v-chip>
  </div>
</template>

<script>
const statusMapping = {
  available: {
    color: 'green lighten-2',
    label: 'Available',
  },
  booked: {
    color: 'orange lighten-2',
    label: 'Booked',
  },
  default: {
    color: '',
    label: 'Unconfirmed',
  },
  ready_soon: {
    color: 'blue lighten-2',
    label: 'Ready soon',
  },
  rent_out: {
    color: 'red lighten-2',
    label: 'Rent out',
  },
};

export default {
  props: {
    color: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: 'available',
    },
  },

  computed: {
    statusConfig() {
      return {
        color: this.color || statusMapping[this.status]?.color || statusMapping.default.color,
        label: statusMapping[this.status]?.label || statusMapping.default.label,
      };
    },
  },
};
</script>
