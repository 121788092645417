<template>
  <div class="fill-height">
    <v-app-bar
      :absolute="$vuetify.breakpoint.smAndDown"
      app
      clipped-left
      color="#008000"
      dark
      :flat="$vuetify.breakpoint.smAndDown"
      height="50px">
      <v-app-bar-nav-icon
        v-if="!$vuetify.breakpoint.mdAndUp"
        @click.stop="showingNavigation = !showingNavigation" />

      <div style="height: 50px">
        <v-text-field
          append-icon="mdi-magnify"
          dense
          flat
          full-width
          hide-details
          label="Cari perlengkapan bayi"
          single-line />
      </div>

      <v-spacer />

      <v-img
        alt="Plovrent"
        class="shrink mr-2"
        contain
        :src="require('../../assets/plovrent.png')"
        transition="scale-transition"
        width="40" />
    </v-app-bar>

    <v-navigation-drawer
      v-model="showingNavigation"
      app
      clipped
      :permanent="$vuetify.breakpoint.mdAndUp">
      <template v-slot:prepend>
        <v-list-item>
          <v-list-item-avatar>
            <v-img
              alt="Plovrent"
              class="shrink"
              contain
              :src="require('../../assets/plovrent.png')"
              transition="scale-transition"
              width="40" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>Plovrent</v-list-item-title>
            <v-list-item-subtitle>Preloved n Rental</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider />

      <v-list
        class="mt-4"
        dense
        rounded>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.route"
          :exact="item.exact">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main class="fill-height">
      <v-container
        class="pa-0"
        fill-height
        fluid>
        <v-card
          class="fill-height px-0"
          flat
          width="100%">

          <router-view />

        </v-card>
      </v-container>
    </v-main>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          title: 'All',
          icon: 'fas fa-person-breastfeeding',
          route: { name: 'home' },
          exact: true,
        },
        {
          title: 'Stroller',
          icon: 'fas fa-baby-carriage',
          route: { name: 'homeToo' },
          exact: true,
        },
        {
          title: 'Bath stuff',
          icon: 'fas fa-bath',
          route: { name: 'about' },
          exact: true,
        },
        {
          title: 'Bike',
          icon: 'fas fa-person-biking',
          route: { name: 'about' },
          exact: true,
        },
        {
          title: 'Baby care',
          icon: 'fas fa-hands-holding-child',
          route: { name: 'about' },
          exact: true,
        },
        {
          title: 'Bed',
          icon: 'fas fa-mattress-pillow',
          route: { name: 'about' },
          exact: true,
        },
      ],
      showingNavigation: false,
    };
  },
};
</script>
