import Vue from 'vue';
import VueRouter from 'vue-router';
import RentalsList from '../views/RentalsList.vue';
import RentalsListToo from '../views/RentalsListToo.vue';
import { AppContainer } from '../layout';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'homepage',
    component: AppContainer,
    redirect: {
      name: 'home',
    },
    children: [
      {
        path: '',
        name: 'home',
        component: RentalsList,
      },
      {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
      },
      {
        path: 'homev2',
        name: 'homeToo',
        component: RentalsListToo,
      },
    ],
  },
  {
    path: '*',
    redirect: {
      path: '/',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
