<template>
  <v-card v-on="$listeners">
    <v-img
      :src="item.src"
      class="white--text align-end"
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
      height="200px" />

    <v-row no-gutters>
      <v-col>
        <v-card-title>{{ item.title }}</v-card-title>

        <v-card-subtitle class="pb-0">{{ item.category }}</v-card-subtitle>

        <v-card-text class="pb-0 text-subtitle-1">
          {{ item.price }}
        </v-card-text>
      </v-col>

      <v-col
        class="text-center"
        cols="auto">
        <AvailabilityStatusChipVue
          class="status-stemple"
          :status="item.status" />

        <v-chip
          v-if="item.status === 'ready_soon'"
          class="ma-2 mt-n1 status-stemple"
          color="blue"
          outlined>
          <v-icon
            left
            x-small>
            fas fa-calendar-check
          </v-icon>
          on {{ newDate }}
        </v-chip>
      </v-col>
    </v-row>

    <v-card-actions>
      <v-spacer></v-spacer>

      <!-- <v-btn
        color="green"
        dark
        :href="`https://wa.me/6282134336282?text=Bismillah, saya mau sewa ${item.title}, apakah available?`"
target="_blank"
        outlined>
        Pesan via Whatsapp
        <v-spacer></v-spacer>
        <v-icon right>
          fa-brands fa-whatsapp
        </v-icon>
      </v-btn> -->

      <span class="px-2">Pesan via Whatsapp</span>
      <v-btn
        bottom
        color="green"
        dark
        fab
        :href="`https://wa.me/6282134336282?text=Bismillah, saya mau sewa ${item.title}, apakah available?`"
target="_blank"
        left
        small>
        <v-icon>fa-brands fa-whatsapp</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import AvailabilityStatusChipVue from './AvailabilityStatusChip.vue';

export default {
  components: {
    AvailabilityStatusChipVue,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      newDate: (new Date()).toLocaleDateString('en-US'),
      showMore: false,
    };
  },
};
</script>

<style scoped>
.status-stemple {
  -ms-transform: rotate(15deg);
  transform: rotate(15deg);
}
</style>
